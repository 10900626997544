body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader-main{
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  background-color: rgba(0, 0, 0, 0.178);
}
.center {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  }
  .popUP{
    border: 5px solid #5a20c4;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  
  }
  .unfollowUser{
    background-color: #ddd;
    padding: 0px 15px;
    line-height: 32px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    color: #555555;
}
.followUser{
    background-color: #5a20c4;
    padding: 0px 15px;
    line-height: 32px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    color: white;
}
.modelOverFlow{
    height: 300px;
    overflow-y: auto;
}

@media (min-height: 500px) {
    .modelOverFlow { height: 300px; }
}

@media (min-height: 800px) {
    .modelOverFlow { height: 600px; }
}